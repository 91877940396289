#wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

#main {
  flex: 1 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.plugin-market-header {
  position: relative;
  border-bottom: 1px solid rgba(0, 40, 100, 0.12) !important;
  padding: 0 1rem;
  box-shadow: 0 0.25rem 0.25rem 0 #eee;
  display: flex;
  align-items: center;
  height: 3.125rem;
}

.plugin-market-header .plugin-toolbar {
  flex: 1;
  display: flex;
  justify-content: center;
  height: 100%;
}

.plugin-market-header .plugin-toolbar .plugin-toolbar-item {
  margin-right: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 0.625rem;
}

.plugin-market-header .plugin-toolbar .plugin-toolbar-item:hover {
  text-decoration: none
}

.plugin-market-content {
  overflow: auto;
  position: relative;
  flex: 1 1;
}

.plugin-market-content .plugin-module {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 0;
  overflow: auto;
}


.plugin-module .plugin-list-container {
  padding: 5rem 0 2.5rem;
  max-width: 1160px;
}

.plugin-market-content .template-module {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 0;
  overflow: auto;
}

.template-module .template-categories {
  max-width: 1160px;
  width: 100%;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5625rem rgba(0, 0, 0, 0.1);
}

.template-categories .template-category-item {
  font-size: 14px;
  color: #999999;
  cursor: pointer;
}

.template-categories .template-category-item.active {
  color: #ED7109;
}

.template-module .template-category {
  max-width: 1160px;
  width: 100%;
}

.template-module .template-category .template-category-name {
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

.plugin-module .empty-tip,
.template-module .empty-tip {
  max-width: 1160px;
  width: 90%;
}

.card-container {
  padding: 0;
  margin: 0.625rem 0.3125rem;
  height: 13.75rem;
  width: 17.5rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5625rem rgba(0, 0, 0, 0.1);
  display: inline-block;
  text-align: center;
}

.card-container:hover {
  border-color: #f09f3f;
  background: #fffbf8;
  cursor: pointer;
}

.card-container .card-image {
  width: 17.375rem;
  height: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.card-container .card-image img {
  height: 120px;
  object-fit: cover;
}

.card-container .card-info {
  padding: 1rem;
  height: 6.25rem;
  overflow: hidden;
}

.card-container .card-info .card-name {
  margin-bottom: 0.5rem;
  text-align: left;
  font-size: 1rem;
  color: #333333;
}

.card-container .card-info .card-description {
  text-align: left;
  font-size: 0.8125rem;
  color: #BCBDC0;
}

.card-detail-dialog {
  height: calc(100% - 56px);
}

.card-detail-dialog .vjs-poster {
  background-size: cover;
}

@media (min-width: 954px) {
  .card-detail-dialog {
    max-width: 954px; /* to overwrite code from seafile-ui */
  }
}

.card-detail-container {
  max-height: 100%;
}

.card-detail-container .card-detail-content {
  padding: 16px;
  max-height: 700px;
  overflow: auto;
}

.card-detail-container .card-detail-content .video-card-detail-image-container .video-js {
  width: 100%;
}

.card-detail-content .plugin-card-detail-image-container {
  overflow-x: scroll;
  white-space: nowrap;
}

.plugin-card-detail-image {
  max-width: none;
  height: 240px;
}

.card-detail-content .card-detail-info {
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card-detail-content .card-detail-info .card-detail-name {
  font-size: 28px;
  font-weight: bold;
  color: #202428;
}

.card-detail-content .template-card-detail-image-container .template-card-detail-image {
  border-radius: 8px;
}

@media all and (orientation : landscape) and (max-width: 768px) { 
  .card-detail-dialog {
    max-width: calc(100% - 50px);
  }
}

@media (max-width: 768px) {
  .video-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-container .card-info .card-description {
    margin-bottom: 0;
  }

  .card-container {
    height: auto;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.1);
  }

  .card-container .card-info {
    height: auto;
    padding: 0.5rem;
  }

  .card-container .card-image {
    width: auto;
    height: auto;
  }

  .card-container .card-image img {
    height: auto;
    min-height: 120px;
  }

  .template-module .template-category .template-category-name {
    text-align: center;
  }
}
